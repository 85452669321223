<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">Shiften</h1>

    <div class="py-4 flex flex-row gap-2">
      <UISelectChauffeur use-query class="max-w-md" label v-model="apiData.user_id" />
      <UISelectFixed :disabled="!apiData.user_id" type="MAAND" v-model="apiData.month" />
      <UISelectMinMax :disabled="!apiData.user_id" :min="2018" :max="dateNow.getFullYear()" label="Jaar" v-model="apiData.year" />
    </div>

    <div class="grid lg:grid-cols-12 gap-4" v-if="apiData.user_id > 0">
      <div class="card mb-4 lg:col-span-4">
        <h2 class="font-semibold text-gray-900">Maand totaal</h2>
        <ul>
          <li class="flex justify-between">
            <b>Totaal Met Klant:</b> {{ millisecondsToHMS(totaalMetKlant) }}
          </li>
          <li class="flex justify-between">
            <b>+ Totaal Zonder Klant:</b> {{ millisecondsToHMS(totaalZonderKlant) }}
          </li>
          <li class="flex flex-col">
            <div class="flex justify-between bg-yellow-200 p-1 border-t-2 border-dashboard-dark rounded-b -mr-1 ml-4 font-bold">
              <b>= Totale ingevulde tijd:</b> {{ millisecondsToHMS(totaal) }}
            </div>
          </li>
          <template v-if="isInfoBeschikbaar">
            <li class="flex justify-between">
              <b>- Extra combinatie tijd (negeren):</b> {{ millisecondsToHMS(totaalCombinatieNegeren) }}
            </li>
            <li class="flex flex-col">
              <div class="flex justify-between bg-green-300 p-1 border-t-2 border-dashboard-dark rounded-b -mr-1 ml-4 font-bold">
                <b>= Totale werktijd:</b> {{ millisecondsToHMS(totaal - totaalCombinatieNegeren) }}
              </div>
            </li>
            <li class="flex justify-between">
              <b>- Tijd op Ophaallocatie:</b> {{ millisecondsToHMS(totaalWachtendOphaallocatie) }}
            </li>
            <li class="flex flex-col">
              <div class="flex justify-between bg-yellow-200 p-1 border-t-2 border-dashboard-dark rounded-b -mr-1 ml-4 font-bold">
                <b>= Totaal productieve tijd:</b> {{ millisecondsToHMS(totaal - totaalCombinatieNegeren - totaalWachtendOphaallocatie) }}
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>

    <div class="grid lg:grid-cols-12 gap-4 items-start" v-if="apiData.user_id > 0">

      <div class="card lg:col-span-4" v-if="store.getters.hasKantoorPermission">
        <h2 class="font-semibold text-gray-900">Dag totalen - Lijst</h2>
        <UITableResponsive :items="items" :headers="['Datum', 'Totaal', 'Met_Klant', 'Zonder_Klant']" class="text-sm">
          <template v-slot:colgroup>
            <colgroup>
              <col class="w-48" />
              <col class="w-auto" />
            </colgroup>
          </template>
          <template v-slot:item-Datum="{ item }">
            <span>{{ dateStringLocale(`${apiData.year}-${apiData.month}-${item.day}`)}}</span>
          </template>
          <template v-slot:item-Totaal="{ item }">
            <span>{{ millisecondsToHMS(item.totaal) }}</span>
            <span v-if="millisecondsToHMS(item.totaal)?.split(':')[0] > 20" class="block border border-orange-300 bg-gray-100 px-1 rounded text-red-500 font-bold text-center">20h+ shift ??</span>
          </template>
          <template v-slot:item-Met_Klant="{ item }">
            <span>{{ millisecondsToHMS(item.totaal_met_klant) }}</span>
          </template>
          <template v-slot:item-Zonder_Klant="{ item }">
            <span>{{ millisecondsToHMS(Number(item.totaal) - Number(item.totaal_met_klant)) }}</span>
          </template>
        </UITableResponsive>
      </div>

      <div class="card lg:col-span-8" v-if="isInfoBeschikbaar">
        <h2 class="font-semibold text-gray-900">Lijst van alle shiften</h2>
        <UITableResponsive :items="data?.list" :headers="headers" class="text-sm">
          <template v-slot:colgroup>
            <colgroup>
              <col class="w-0" />
              <col class="w-0" />
              <col class="w-0" />
              <col class="w-0" />
              <col class="w-0" />
              <col class="w-0" />
              <col class="w-auto" />
            </colgroup>
          </template>
          <template v-slot:item-Start="{ item }">
            <div class="flex flex-col items-start">
              <ButtonRitCombinatieStatus
                class="w-auto mx-0"
                v-if="item.combinatie_status > 0"
                :status="item.combinatie_status"
                disabled
              />
              <span v-if="item.start">
                {{ dateTimeString(item.start)}}
                <UIBadgeShiftClamped v-if="item.start_isClamped" />
              </span>
              <span v-else class="text-red-500">Geen shift start?</span>
            </div>
          </template>
          <template v-slot:item-Einde="{ item }">
            <span v-if="item.einde">
              {{ dateTimeString(item.einde) }}
              <UIBadgeShiftClamped v-if="item.einde_isClamped" />
            </span>
            <span v-else class="text-red-500">Geen shift einde?</span>
          </template>
          <template v-slot:item-Wachtend_Ophaallocatie="{ item }">
            <span v-if="item.aangekomen && item.klant_ingestapt">
              {{ millisecondsToHMS(berekenWachtendOphaallocatie(item)) }}
              <UIBadgeShiftClamped v-if="item.aangekomen_isClamped || item.klant_ingestapt_isClamped" />
            </span>
            <span v-else class="text-red-500">Shift niet volledig!</span>
          </template>
          <template v-slot:item-Tijd_Met_Klant="{ item }">
            <span v-if="item.klant_ingestapt && item.klant_afgezet">
              {{ millisecondsToHMS(berekenMetKlant(item)) }}
              <UIBadgeShiftClamped v-if="item.klant_ingestapt_isClamped || item.klant_afgezet_isClamped" />
            </span>
            <span v-else class="text-red-500">Shift niet volledig!</span>
            <span v-if="item.is_no_show" class="block border border-orange-300 bg-gray-100 px-1 rounded text-red-500 font-bold text-center">NO SHOW!</span>
          </template>
          <template v-slot:item-Tijd_Zonder_Klant="{ item }">
            <span v-if="item.klant_afgezet && item.klant_ingestapt && item.start && item.einde">
              {{ millisecondsToHMS(berekenZonderKlant(item)) }}
              <UIBadgeShiftClamped v-if="item.start_isClamped || item.einde_isClamped" />
            </span>
            <span v-else class="text-red-500">Shift niet volledig!</span>
          </template>
          <template v-slot:item-Totale_Tijd="{ item, index }">
            <div class="flex flex-col">
              <span>{{ millisecondsToHMS(item.time) }}</span>
              <span v-if="item.combinatie_status > 0 && itemsCombinatieNegeren.find(el => el.id === item.id)" class="block absolute bottom-0.5 left-48 border border-orange-300 bg-gray-100 px-1 rounded text-orange-500">
                Deze overlapping (door combinatie) zal afgetrokken worden in "Extra combinatie tijd"
              </span>
              <span v-else-if="index > 0 && new Date(data?.list[index - 1].einde).getTime() > new Date(item.start).getTime()" class="block absolute bottom-0.5 left-48 border border-orange-300 bg-gray-100 px-1 rounded text-orange-500">
                Overlapping gededecteerd (combinatie?)
              </span>
            </div>
          </template>
          <template v-slot:item-Acties="{ item }">
            <div class="inline-flex flex-col gap-1">
              <button class="btn warning" @click="handleEditShift(item)" v-if="isActiesBeschikbaar">
                <i class="fas fa-edit"></i> Bewerken
              </button>
              <ButtonRitInfo :disabled="!(item.rit_id > 0)" :id="item.rit_id" :deel="item.is_retour ? 'retour' : 'heen'" target="_blank" />
            </div>
          </template>
        </UITableResponsive>
      </div>

      <BarShiften
        v-if="isGrafiekBeschikbaar"
        class="card"
        :class="{
          'lg:col-span-12': isInfoBeschikbaar,
          'lg:col-span-9': !isInfoBeschikbaar ,
        }"
        :items="items"
      />
    </div>

  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
import UIBadgeShiftClamped from '@/components/UI/Badge/ShiftClamped.vue'
import ButtonRitInfo from '@/components/UI/Button/RitInfo.vue'

import BarShiften from '@/components/Charts/BarShiften.vue'

import useModal from '@/hooks/useModal'
import useGetApi from '@/hooks/useGetApi'
import { dateTimeString, dateStringLocale, millisecondsToHMS } from '@/functions/formatDate'
import useRouteQueryInt from '@/hooks/useRouteQueryInt'

import ButtonRitCombinatieStatus from '@/components/UI/Button/RitCombinatieStatus.vue'

const store = useStore()
const [openFixedModal] = useModal({ fixed: true })

const isActiesBeschikbaar = computed(() => store.getters.hasAdminPermission)
const isGrafiekBeschikbaar = computed(() => store.getters.hasAdminPermission)
const isInfoBeschikbaar = computed(() => store.getters.hasAdminPermission)

const headers = computed(() => {
  return ['Start', 'Einde', 'Wachtend_Ophaallocatie', 'Tijd_Met_Klant', 'Tijd_Zonder_Klant', 'Totale_Tijd', isActiesBeschikbaar.value && 'Acties'].filter(el => el)
})

const dateNow = new Date()
const apiData = ref({
  user_id: useRouteQueryInt('chauffeur').value || store.state.profile?.id || null,
  month: dateNow.getMonth() + 1,
  year: dateNow.getFullYear(),
})

// loading, reset, getData, cancel
const { data, getData } = useGetApi('/api/dashboard/shiften/list', apiData, { watch: true, skipMounted: !apiData.value.user_id })

const minimalTime = computed(() => new Date(apiData.value.year, apiData.value.month - 1, 1).getTime())
const parseMinimalTime = (time) => Math.max(minimalTime.value, new Date(time || 0).getTime())

const berekenWachtendOphaallocatie = (item) => {
  if (!item.aangekomen || !item.klant_ingestapt) return 0
  return parseMinimalTime(item.klant_ingestapt) - parseMinimalTime(item.aangekomen)
}

const berekenMetKlant = (item) => {
  if (!item.klant_ingestapt || !item.klant_afgezet) return 0
  return parseMinimalTime(item.klant_afgezet) - parseMinimalTime(item.klant_ingestapt)
}

const berekenZonderKlant = (item) => {
  if (!item.start || !item.einde) return 0
  const metKlant = berekenMetKlant(item)
  const totaal = parseMinimalTime(item.einde) - parseMinimalTime(item.start)
  return totaal - metKlant
}
// const berekenTotaal = (item) => {
//   if (!item.einde || !item.start) return 0
//   return parseMinimalTime(item.einde) - parseMinimalTime(item.start)
// }

const handleEditShift = (shiftItem) => {
  const shift = Object.assign({}, { ...shiftItem })
  shift.start = shift.start_original || shift.start
  shift.aangekomen = shift.aangekomen_original || shift.aangekomen
  shift.klant_ingestapt = shift.klant_ingestapt_original || shift.klant_ingestapt
  shift.klant_afgezet = shift.klant_afgezet_original || shift.klant_afgezet
  shift.einde = shift.einde_original || shift.einde

  delete shift.start_original
  delete shift.aangekomen_original
  delete shift.klant_ingestapt_original
  delete shift.klant_afgezet_original
  delete shift.einde_original

  openFixedModal('FORM_SHIFT', { shift, callback: getData })
}

const items = computed(() => Object.entries(data?.value?.daily || {}).map(([day, { totaal, totaal_met_klant }]) => ({ day, totaal, totaal_met_klant })))

const itemsCombinatieNegeren = computed(() => (data?.value?.list || []).filter((item) => {
  if (!item.combinatie_status) return false
  const combinatieShiften = data?.value?.list.filter((el) => el.combinatie_status === item.combinatie_status && item.time > 0 && Math.abs(new Date(el.start).getTime() - new Date(item.start).getTime()) < 1000 * 60 * 60 * 3).sort((a, b) => b.time - a.time)
  if (combinatieShiften.length <= 1) return false
  return combinatieShiften[0].id !== item.id
}))

const totaal = computed(() => items.value.reduce((prev, next) => prev + (next.totaal || 0), 0))
const totaalCombinatieNegeren = computed(() => itemsCombinatieNegeren.value.reduce((prev, next) => prev + (next.time || 0), 0))
// const totaalFromItems = computed(() => data?.value?.list.reduce((prev, next) => prev + berekenTotaal(next), 0))
const totaalWachtendOphaallocatie = computed(() => data?.value?.list.reduce((prev, next) => prev + berekenWachtendOphaallocatie(next), 0))
const totaalMetKlant = computed(() => data?.value?.list.reduce((prev, next) => prev + berekenMetKlant(next), 0))
const totaalZonderKlant = computed(() => data?.value?.list.reduce((prev, next) => prev + berekenZonderKlant(next), 0))
</script>

<style lang="scss" scoped>
ul {
  border-collapse: collapse;
  @apply border border-dashboard-dark;
}
ul li {
  @apply border border-dashboard-dark;
  @apply px-2 py-1 pr-4;
  border-collapse: collapse;
}
</style>
